import { loginRequest, graphConfig } from "../config/msalConfig";
import { msalInstance } from "../index";

import apiConfig from "../config/api";

const backend_api = {
    apiStatus: async () => {
        const API_URL = `${apiConfig.backend[process.env.REACT_APP_API_ENV]}`;

        var fetchURL = `${API_URL}status`

        var api_response=""

        await fetch(fetchURL, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                api_response = result
            })
            .catch((error) => {
                console.log("Error", error);
                api_response = "There was an error"
            })

        return api_response;
    },
    addOrganisation: async (accessToken, tenantData, userData) => {
        if (!accessToken) {
            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
            accessToken = response.accessToken;
        }

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        const API_URL = `${apiConfig.backend[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
            }`;

        var fetchURL = `${API_URL}/org/new-organisation`
        
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = date + "-" + month + "-" + year;

        var api_body = JSON.stringify({
            "id": `details`,
            "tid": `${tenantData.tid}`,
            "tdn": `${tenantData.tdn}`,
            "jobTitle": `${userData.jobTitle}`,
            "mobilePhone": `${userData.mobilePhone}`,
            "date": currentDate
        });

        var response = "API Complete"

        await fetch(fetchURL, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": bearer
            },
            body: api_body
        })
            .then(response => response.json())
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.log("Error", error);
                response = "There was an error"
            })

        return response;
    },
    addQuestion: async (accessToken, tenantData, chatData) => {
        if (!accessToken) {
            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
            accessToken = response.accessToken;
        }

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        const API_URL = `${apiConfig.backend[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
            }`;

        var fetchURL = `${API_URL}/ai/ask-question`

        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = date + "-" + month + "-" + year;

        var api_body = JSON.stringify({
            "tid": tenantData.tid,
            "oid": "",
            "date": currentDate,
            "session_id": chatData.session_id,
            "question_id": chatData.id,
            "model": chatData.modeld,
            "question": chatData.question,
            "response": chatData.response,
            "references": chatData.references,
            "sources": chatData.sources,
            "latest": chatData.latest,
            "tone": chatData.tone
        });

        var response = "API Complete"

        await fetch(fetchURL, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": bearer
            },
            body: api_body
        })
            .then(response => response.json())
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.log("Error", error);
                response = "There was an error"
            })

        return response;
    },
    addResponse: async (accessToken, tenantData, chatData, aiResponse, sources, input_tokens, output_tokens, total_tokens) => {
        if (!accessToken) {
            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
            accessToken = response.accessToken;
        }

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        const API_URL = `${apiConfig.backend[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
            }`;

        var fetchURL = `${API_URL}/ai/update-response`

        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = date + "-" + month + "-" + year;

            console.log("Response api data ", chatData)

        var api_body = JSON.stringify({
            "tid": tenantData.tid,
            "oid": "",
            "date": currentDate,
            "session_id": chatData.session_id,
            "question_id": chatData.id,
            "model": chatData.model,
            "question": chatData.question,
            "response": aiResponse,
            "references": chatData.references,
            "sources": sources,
            "latest": chatData.latest,
            "tone": chatData.tone,
            "inputTokens": input_tokens,
            "outputToken": output_tokens,
            "totalTokens": total_tokens
        });

        var response = "API Complete"

        await fetch(fetchURL, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": bearer
            },
            body: api_body
        })
            .then(response => response.json())
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.log("Error", error);
                response = "There was an error"
            })

        return response;
    }
};

export default backend_api